import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide66/image/imgPortadaGuia.png"
import img1 from "@components/pageGuide/guides/guide66/image/img.png"
import slider1 from "@components/pageGuide/guides/guide66/image/silder1.png"
import slider2 from "@components/pageGuide/guides/guide66/image/silder2.png"
import slider3 from "@components/pageGuide/guides/guide66/image/silder3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
    9 ideas para automatizar la atención al cliente en una universidad 
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
    Automatizar la atención al cliente en una universidad simplifica procesos clave. Un chatbot puede responder preguntas frecuentes sobre admisiones, horarios o pagos sin necesidad de intervención humana.  
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
También puedes enviar recordatorios de pagos, notificaciones de eventos o encuestas de satisfacción de forma automática.  
<br /><br />
Al integrar estos sistemas con las plataformas internas, es posible gestionar solicitudes y ofrecer respuestas más rápidas, mejorando la experiencia de estudiantes y aspirantes. 
</p>
)

const data = {
  start: {
    support: "Guía | Educación",
    title: title,
    description: textDescription,
    classtext: "guide__description-container",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Configura un chatbot educativo para FAQs.",
      },
      {
        key: 2,
        text: "Automatiza el flujo de mailing.",
      },
      {
        key: 3,
        text: "Refuerza las estrategias de recopilación de datos.",
      },
      {
        key: 4,
        text: "Diseña páginas de destino efectivas.",
      },
      {
        key: 5,
        text: "Automatiza el seguimiento de admisiones.",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
